<template>
  <div v-if="!loading">
    <div v-if="accounts.length === 0">
      <h1>Welcome to your account!</h1>
      <p>
        To get started, click below to link your account (the details of this
        should be displayed on your latest bill or welcome letter)
      </p>
      <p>
        Add an extra layer of security to your account by enabling e-mail code
        verification
      </p>
      <b-button
        @click="associateAccount"
        class="account-btn"
        size="md"
        variant="success"
        >Link New Account</b-button
      >
    </div>
    <div v-else>
      <h1>Welcome Back!</h1>
      <p>Select your apartment below to view usage and manage your account</p>
    </div>

    <div class="row" style="margin: 40px 20px;">
      <div class="col-md-1"></div>
      <div v-if="accounts.length > 0" class="col-md-5 account-select first">
        <AccountsList></AccountsList>
      </div>

      <div
        class="account-select"
        :class="{
          'col-md-12': accounts.length === 0,
          'col-md-5': accounts.length > 0
        }"
      >
        <AccountManagement></AccountManagement>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
import AccountsList from "@/views/Account/Components/AccountsList";
import { createAccount } from "@/components/mixins/create-account";
import AccountManagement from "@/views/Account/Components/AccountManagement";
export default {
  name: "Account",
  components: { AccountManagement, AccountsList },
  mixins: [createAccount],
  data() {
    return {
      loading: true
    };
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  computed: {
    accounts() {
      return this.$store.state.auth.accounts;
    }
  },
  methods: {
    fetchData() {
      if (this.$store.state.auth.accounts !== null) {
        this.checkFirstRequest();
        this.loading = false;
        return;
      }

      const title = "Fetching Data";
      const subtitle = "Compiling Account Data";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      this.$store
        .dispatch("account")
        .then(() => {})
        .catch(() => {
          const title = "Invalid request";
          const subtitle = "We were unable to action your request";
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch("loaderCancel");
        });
    },
    checkFirstRequest() {
      if (!this.$store.state.auth.firstRequest) {
        return false;
      }

      if (this.accounts.length === 1) {
        this.$store.dispatch("resetFirstRequest");
        this.$router.push(
          "/" + this.accounts[0].type + "/" + this.accounts[0].reference
        );
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 20px;
}

.sub {
  font-size: 0.8rem;
  display: block;
}

@media (min-width: $breakpointTablet) {
  .account-select {
    &.first {
      border-right: 1px solid #ddd;
    }
  }
}

.btn.account-btn {
  border: 1px solid #8dc04c;
  background-color: #8dc04c;
  color: white;
  margin: 6px;
}
</style>
